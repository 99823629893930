import {
  NavSkeleton,
  NavSkeletonProps,
} from "@/layouts/base-layout/navigation/nav-skeleton";
import { MainSection } from "@/layouts/base-layout/navigation/main-section";

import { useBaseLayout } from "@/layouts/base-layout/shared";
import { InformationSection } from "@/layouts/base-layout/navigation/information-section";
import React, { Suspense } from "react";
import { Skeleton } from "@llamaindex/component/ui/skeleton";
import { runtimeFlags } from "@/lib/runtime";

export const Navigation = (props: Pick<NavSkeletonProps, "layoutRef">) => {
  const { serverNavigationItems } = useBaseLayout();
  return (
    <NavSkeleton layoutRef={props.layoutRef}>
      <Suspense fallback={<Skeleton className="h-[125px] w-full rounded-xl" />}>
        <MainSection />
      </Suspense>
      <div className="grow" />
      <Suspense fallback={<Skeleton className="h-[125px] w-full rounded-xl" />}>
        {runtimeFlags.authMode === "self-host-byoc" ? null : (
          <InformationSection />
        )}
      </Suspense>
      {serverNavigationItems}
      <div className="mb-8" />
    </NavSkeleton>
  );
};
