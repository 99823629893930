"use client";
import clsx from "clsx";
import {
  BarChartBig,
  FileOutput,
  FolderTree,
  Grid,
  Key,
  Settings,
} from "lucide-react";
import {
  ColoredBarChart,
  ColoredFileOutput,
  ColoredFolderTree,
  ColoredGrid,
  ColoredSettings,
} from "@/components/icons";
import { DynamicDocumentLink } from "@/components/section/dynamic-document-link";
import { Icons } from "@llamaindex/component/ui/icons";
import React, { useMemo } from "react";
import Link from "next/link";
import { motion } from "framer-motion";
import ToolTipper from "@llamaindex/component/ui/tooltipper";
import { useProjects } from "@/hooks/project";
import { useAppStore } from "@/store";
import { usePathname } from "next/navigation";
import { LoginProvider } from "@/lib/firebase/auth";
import { useAuth } from "@/hooks/auth";
import { runtimeFlags } from "@/lib/runtime";

export const NAV_ITEM_TOOLTIP_DIRECTION = "right";

interface NavItemProps {
  href: string;
  icon: React.ReactNode;
  activeIcon: React.ReactNode;
  label: React.ReactNode;
  isActive: boolean;
  isCollapsed: boolean;
}

const NavItem: React.FC<NavItemProps> = ({
  href,
  icon,
  activeIcon,
  label,
  isActive,
  isCollapsed,
}) => {
  const content = (
    <Link
      href={href}
      className="flex cursor-pointer items-center gap-2 rounded-md text-sm font-normal transition-all duration-200"
    >
      {isActive ? (
        activeIcon
      ) : (
        <motion.div layout="position" className="group-hover:hidden">
          {icon}
        </motion.div>
      )}
      {!isCollapsed && <div className="hover:text-slate-600">{label}</div>}
    </Link>
  );

  return isCollapsed ? (
    <ToolTipper side={NAV_ITEM_TOOLTIP_DIRECTION} content={label}>
      {content}
    </ToolTipper>
  ) : (
    content
  );
};

interface NavSectionProps {
  title: string;
  isCollapsed: boolean;
  children: React.ReactNode;
}
const NavSection: React.FC<NavSectionProps> = ({
  title,
  isCollapsed,
  children,
}) => (
  <div
    className={clsx(
      "mb-4 flex w-full flex-col gap-2",
      isCollapsed ? "items-center" : "items-start",
    )}
  >
    <div
      className={clsx(
        "text-[11px] font-normal uppercase tracking-wide text-slate-400",
      )}
    >
      {!isCollapsed ? title : <span className="invisible">-</span>}
    </div>
    {children}
  </div>
);

export const MainSection: React.FC = () => {
  const isCollapsed = useAppStore((store) => store.isCollapsed);
  const { user } = useAuth();
  const { data: projects } = useProjects();

  const projectId = useAppStore((store) => store.projectId);

  const pathname = usePathname();
  const currentProject = projects.find((project) => project.id === projectId);

  const arizeUrl = useMemo(() => {
    switch (user?.lastLoginProvider) {
      case LoginProvider.GITHUB:
        return "https://llamatrace.com/auth/github";
      default:
        return "https://llamatrace.com/auth/google";
    }
  }, [user?.lastLoginProvider]);

  return (
    <section
      role="navigation"
      className={clsx("flex flex-col gap-2", isCollapsed && "items-center")}
    >
      <NavSection title="Tools" isCollapsed={isCollapsed}>
        <NavItem
          href="/parse"
          icon={<Grid className="size-4" />}
          activeIcon={<ColoredGrid className="size-4" />}
          label="Parse"
          isActive={pathname === "/parse"}
          isCollapsed={isCollapsed}
        />
        {user?.allowed.index && projectId && (
          <NavItem
            href={`/project/${projectId}/pipeline`}
            icon={<FolderTree className="size-4" />}
            activeIcon={<ColoredFolderTree className="size-4" />}
            label="Index"
            isActive={pathname === `/project/${projectId}/pipeline`}
            isCollapsed={isCollapsed}
          />
        )}
        {user?.allowed.eval && projectId && (
          <NavItem
            href={`/project/${projectId}/evals`}
            icon={<BarChartBig className="size-4" />}
            activeIcon={<ColoredBarChart className="size-4" />}
            label="Evals"
            isActive={pathname === `/project/${projectId}/evals`}
            isCollapsed={isCollapsed}
          />
        )}
        {user?.allowed.extraction &&
          projectId &&
          runtimeFlags.authMode !== "self-host-byoc" && (
            <NavItem
              href={`/project/${projectId}/extraction`}
              icon={<FileOutput className="size-4" />}
              activeIcon={<ColoredFileOutput className="size-4" />}
              label={
                <>
                  <span>Extraction</span>
                  <span className="ml-2 rounded bg-[#e1f6fd] px-[9px] py-[3px] text-xs font-medium text-[#00749e]">
                    Beta
                  </span>
                </>
              }
              isActive={pathname === `/project/${projectId}/extraction`}
              isCollapsed={isCollapsed}
            />
          )}
      </NavSection>

      <NavSection title="Resources" isCollapsed={isCollapsed}>
        {currentProject && (
          <NavItem
            href={`/organization/${currentProject.organization_id}/settings/organization`}
            icon={<Settings className="size-4" />}
            activeIcon={<ColoredSettings className="size-4" />}
            label="Settings"
            isActive={
              pathname.startsWith(`/organization`) &&
              pathname.includes("settings")
            }
            isCollapsed={isCollapsed}
          />
        )}
        <NavItem
          href="/api-key"
          icon={<Key className="size-4" />}
          activeIcon={<Key className="size-4" />}
          label="API Key"
          isActive={pathname === "/api-key"}
          isCollapsed={isCollapsed}
        />
        <DynamicDocumentLink isCollapsed={isCollapsed} />
      </NavSection>

      {runtimeFlags.authMode !== "self-host-byoc" && (
        <NavSection title="Partner Integrations" isCollapsed={isCollapsed}>
          <NavItem
            href={arizeUrl}
            icon={<Icons.arize className="size-4" />}
            activeIcon={<Icons.arize className="size-4" />}
            label="LlamaTrace with Arize"
            isActive={false}
            isCollapsed={isCollapsed}
          />
        </NavSection>
      )}
    </section>
  );
};
